import { default as data_45privacy15gZNCtUQ4Meta } from "/app/frontend/src/pages/data-privacy.vue?macro=true";
import { default as indexgfojaT20hbMeta } from "/app/frontend/src/pages/demo/index.vue?macro=true";
import { default as indexMF3ffkfT50Meta } from "/app/frontend/src/pages/demotest/index.vue?macro=true";
import { default as confirmLeE8KUkP6nMeta } from "/app/frontend/src/pages/htb/signup/confirm.vue?macro=true";
import { default as indexqLHOD07IFzMeta } from "/app/frontend/src/pages/htb/signup/index.vue?macro=true";
import { default as successmQDnhO8EpaMeta } from "/app/frontend/src/pages/htb/signup/success.vue?macro=true";
import { default as indexMf5jkORaCRMeta } from "/app/frontend/src/pages/index.vue?macro=true";
import { default as index6tsFOSXP2dMeta } from "/app/frontend/src/pages/login/index.vue?macro=true";
import { default as reauthnwza5tMdRrMeta } from "/app/frontend/src/pages/login/reauth.vue?macro=true";
import { default as featureszWNBObP3VOMeta } from "/app/frontend/src/pages/order/features.vue?macro=true";
import { default as indexebxJeaISULMeta } from "/app/frontend/src/pages/order/index.vue?macro=true";
import { default as licenseVO8ocq4YRPMeta } from "/app/frontend/src/pages/order/license.vue?macro=true";
import { default as quote6MV2ZlBIBQMeta } from "/app/frontend/src/pages/order/quote.vue?macro=true";
import { default as terms_45of_45serviceBpNpt0nDQSMeta } from "/app/frontend/src/pages/order/terms-of-service.vue?macro=true";
import { default as confDYeDN9PntqMeta } from "/app/frontend/src/pages/oscp/signup/conf.vue?macro=true";
import { default as confirmFko64ZhIBeMeta } from "/app/frontend/src/pages/oscp/signup/confirm.vue?macro=true";
import { default as indexNMl9Sjb7EiMeta } from "/app/frontend/src/pages/oscp/signup/index.vue?macro=true";
import { default as successuAmbAaHYzCMeta } from "/app/frontend/src/pages/oscp/signup/success.vue?macro=true";
import { default as terms_45of_45serviceZlo3ljZmVlMeta } from "/app/frontend/src/pages/oscp/signup/terms-of-service.vue?macro=true";
import { default as apitokensNzrX0HbFb7Meta } from "/app/frontend/src/pages/users/self/apitokens.vue?macro=true";
import { default as indexqsOtAAd0dxMeta } from "/app/frontend/src/pages/users/self/index.vue?macro=true";
import { default as securityFhHzVi7ffMMeta } from "/app/frontend/src/pages/users/self/security.vue?macro=true";
import { default as selfAUDD3dmxkeMeta } from "/app/frontend/src/pages/users/self.vue?macro=true";
export default [
  {
    name: "data-privacy",
    path: "/data-privacy",
    meta: data_45privacy15gZNCtUQ4Meta || {},
    component: () => import("/app/frontend/src/pages/data-privacy.vue").then(m => m.default || m)
  },
  {
    name: "demo",
    path: "/demo",
    meta: indexgfojaT20hbMeta || {},
    component: () => import("/app/frontend/src/pages/demo/index.vue").then(m => m.default || m)
  },
  {
    name: "demotest",
    path: "/demotest",
    meta: indexMF3ffkfT50Meta || {},
    component: () => import("/app/frontend/src/pages/demotest/index.vue").then(m => m.default || m)
  },
  {
    name: "htb-signup-confirm",
    path: "/htb/signup/confirm",
    meta: confirmLeE8KUkP6nMeta || {},
    component: () => import("/app/frontend/src/pages/htb/signup/confirm.vue").then(m => m.default || m)
  },
  {
    name: "htb-signup",
    path: "/htb/signup",
    meta: indexqLHOD07IFzMeta || {},
    component: () => import("/app/frontend/src/pages/htb/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "htb-signup-success",
    path: "/htb/signup/success",
    meta: successmQDnhO8EpaMeta || {},
    component: () => import("/app/frontend/src/pages/htb/signup/success.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/frontend/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: index6tsFOSXP2dMeta || {},
    component: () => import("/app/frontend/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-reauth",
    path: "/login/reauth",
    meta: reauthnwza5tMdRrMeta || {},
    component: () => import("/app/frontend/src/pages/login/reauth.vue").then(m => m.default || m)
  },
  {
    name: "order-features",
    path: "/order/features",
    meta: featureszWNBObP3VOMeta || {},
    component: () => import("/app/frontend/src/pages/order/features.vue").then(m => m.default || m)
  },
  {
    name: "order",
    path: "/order",
    meta: indexebxJeaISULMeta || {},
    component: () => import("/app/frontend/src/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: "order-license",
    path: "/order/license",
    meta: licenseVO8ocq4YRPMeta || {},
    component: () => import("/app/frontend/src/pages/order/license.vue").then(m => m.default || m)
  },
  {
    name: "order-quote",
    path: "/order/quote",
    meta: quote6MV2ZlBIBQMeta || {},
    component: () => import("/app/frontend/src/pages/order/quote.vue").then(m => m.default || m)
  },
  {
    name: "order-terms-of-service",
    path: "/order/terms-of-service",
    meta: terms_45of_45serviceBpNpt0nDQSMeta || {},
    component: () => import("/app/frontend/src/pages/order/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "oscp-signup-conf",
    path: "/oscp/signup/conf",
    meta: confDYeDN9PntqMeta || {},
    component: () => import("/app/frontend/src/pages/oscp/signup/conf.vue").then(m => m.default || m)
  },
  {
    name: "oscp-signup-confirm",
    path: "/oscp/signup/confirm",
    meta: confirmFko64ZhIBeMeta || {},
    component: () => import("/app/frontend/src/pages/oscp/signup/confirm.vue").then(m => m.default || m)
  },
  {
    name: "oscp-signup",
    path: "/oscp/signup",
    meta: indexNMl9Sjb7EiMeta || {},
    component: () => import("/app/frontend/src/pages/oscp/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "oscp-signup-success",
    path: "/oscp/signup/success",
    meta: successuAmbAaHYzCMeta || {},
    component: () => import("/app/frontend/src/pages/oscp/signup/success.vue").then(m => m.default || m)
  },
  {
    name: "oscp-signup-terms-of-service",
    path: "/oscp/signup/terms-of-service",
    meta: terms_45of_45serviceZlo3ljZmVlMeta || {},
    component: () => import("/app/frontend/src/pages/oscp/signup/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: selfAUDD3dmxkeMeta?.name,
    path: "/users/self",
    component: () => import("/app/frontend/src/pages/users/self.vue").then(m => m.default || m),
    children: [
  {
    name: "users-self-apitokens",
    path: "apitokens",
    component: () => import("/app/frontend/src/pages/users/self/apitokens.vue").then(m => m.default || m)
  },
  {
    name: "users-self",
    path: "",
    component: () => import("/app/frontend/src/pages/users/self/index.vue").then(m => m.default || m)
  },
  {
    name: "users-self-security",
    path: "security",
    component: () => import("/app/frontend/src/pages/users/self/security.vue").then(m => m.default || m)
  }
]
  }
]