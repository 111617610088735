import revive_payload_client_4sVQNw7RlN from "/app/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_RMzWu406ID from "/app/frontend/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_KgADcZ0jPj from "/app/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_wMKVLl9fyU from "/app/frontend/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import chunk_reload_client_UciE0i6zes from "/app/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/app/frontend/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import auth_e0FkfVuy48 from "/app/frontend/src/plugins/auth.ts";
import toast_ammHSktxCk from "/app/frontend/src/plugins/toast.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/app/frontend/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_RMzWu406ID,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vuetify_no_client_hints_wMKVLl9fyU,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  auth_e0FkfVuy48,
  toast_ammHSktxCk,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]